.travellerDetailDesktop {
  margin-top: 60px;
}

.travellerDetailImg {
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 40px 100px 0px;
}

.travllerDetailLeftCard {
  width: 297px;
  height: 533px;
  display: flex;
  flex-direction: column;
  background: #f6f6f6;
  border: 1px solid #f1f1f1;
  border-radius: 30px;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.avatar {
  background-color: #20b08f;
  color: white;
  border-radius: 30px;
  width: 180px;
  height: 180px;
  font-size: 60px;
  font-weight: 500;
}

.travellerDetailsList {
  display: flex;
  gap: 40px 15px;
  flex-wrap: wrap;
}

.travllerDetailMobileScreen {
  display: none;
}

.travllerDetailMobileHeader {
  background-color: #ffffff;
  display: flex;
  gap: 10px;
  padding: 10px;
}

.avatarMobile {
  background-color: #20b08f;
  color: white;
  border-radius: 24px;
  width: 80px;
  height: 80px;
  font-size: 60px;
  font-weight: 500;
}

.travllerDetailTripCard {
  min-width: 150px;
  width: 260px;
  flex-shrink: 0;
  margin-right: 10px;
  background-color: #ddd;
  display: flex;
  border-radius: 21px;
  padding: 6px;
  box-shadow: 0px 2px 18px 3px #00000005;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 15px;
  cursor: pointer;
  min-height: 290px;
}

.backgroundImg {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  min-height: 60px;
  border-radius: 12px;
  position: relative;
  object-fit: cover;
}

.travllerDetailTripCardDescription {
  display: flex;
  flex-direction: row;
  gap: 23px;
  margin-top: 4px;
}

.cardDescriptionItems {
  display: flex;
  flex-direction: row;
  gap: 4px;
}

@media (max-width: 768px) {
  .travellerDetailDesktop {
    display: none;
  }

  .travellerDetailsList {
    gap: 20px 0px;
  }

  .travllerDetailMobileScreen {
    display: block;
    margin-top: 115px;
  }

  .travllerDetailsAllDetailsMobile {
    padding: 14px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  .travllerDetailTripCard {
    width: 47%;
    min-height: 230px;
  }
  .travllerDetailTripCardDescription {
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-top: 12px;
  }
}

@media (max-width: 350px) {
  .travllerDetailTripCard {
    width: 100%;
  }
}
