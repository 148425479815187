.downloadAppCard {
  width: 100%;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  border-radius: 32px;
  background-color: #ffffff;
  box-shadow: 0px 0px 28px 3px #0000000f;
  gap: 10px;
  background: linear-gradient(266.14deg, #454c66 0%, #2a314a 100%);
  position: relative;
  padding: 60px 80px;
}

.imageContainer {
  position: absolute;
  right: 70px;
  bottom: 0;
  background-repeat: no-repeat, no-repeat;
  background-position: top right, top left;
}

.downloadAppLeft {
  width: 40%;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.vacationListCard {
  width: 100%;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  border-radius: 32px;
  background-color: #ffffff;
  box-shadow: 0px 0px 28px 3px #0000000f;
  gap: 10px;
  background: linear-gradient(90deg, #35404a 0%, #1a2128 100%);
  position: relative;
  padding: 60px 80px;
}

.vacationListCardIn {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}

.vacationListCards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  width: 100%;
}

.cardVacation {
  min-width: 175px;
  gap: 0px;
  border-radius: 16px;
  background: #232a30;
  box-shadow: 0px 0px 24px 1px #ffffff12;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 7px;
}

.AppDetailsMain {
  gap: 0px;
  /* opacity: 0px; */
  background: #f0f4f9;
  display: flex;
  border-radius: 21px;
  justify-content: space-between;
  position: relative;
  /* Make it relative to position pseudo-element */
  overflow: hidden;
  /*Ensure pseudo-element doesn't overflow */
}

.AppDetailsMain::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 600px;
  height: 100%;
  background: #fafafa;
  transform: skewX(-46deg);
  transform-origin: left bottom;
}

.contentAppDetails {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 46%;
  padding: 90px 15px 90px 122px;
}

.imageContainerAppDetails {
  position: relative;
  right: 55px;
  overflow: hidden;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 10;
}

.travellerCard {
  flex-shrink: 0;
  margin-right: 10px;
  display: flex;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.imageContainerTraveller {
  position: relative;
  width: 170px;
  height: 230px;
  border-radius: 114px;
  overflow: hidden;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 128, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  border-radius: 114px;
  transition: opacity 0.3s;
}

.imageContainerTraveller:hover .overlay {
  opacity: 1;
  cursor: pointer;
}

.travellerImage {
  width: 100%;
  height: 100%;
  border-radius: 114px;
  transition: opacity 0.3s;
  object-fit: cover;
}

.travellersDesc {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.featuredUserAvatar {
  background-color: #20b08f;
  color: white;
  width: 100%;
  height: 100%;
  border-radius: 0px;
  font-size: 60px;
  font-weight: 500;
}

.bgImgTravelGuides {
  background-size: cover;
  background-position: center;
  height: 500px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  object-fit: cover;
}

.searchItenairyHeaderIn {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
  text-align: center;
}

.searchDetailPageContent {
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-top: 90px;
}

.searchPageContentIn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.searchPageContentBox {
  display: flex;
  gap: 40px 15px;
  flex-wrap: wrap;
  justify-content: center;
}

/* 900 */
@media (max-width: 900px) {
  .cardVacation {
    min-width: 128px;
    width: 130px;
    height: 135px;
  }

  .vacationListCard {
    padding: 45px 25px;
    border-radius: 0px;
  }

  .AppDetailsMain::after {
    display: none;
  }

  .AppDetailsMain {
    display: flex;
    flex-direction: column;
    border-radius: 0px;
  }

  .contentAppDetails {
    display: flex;
    flex-direction: column;
    gap: 0px;
    width: 100%;
    padding: 30px;
    align-items: center;
  }
  .imageContainerAppDetails {
    right: 0px;
  }

  .searchDetailPageContent {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 15px;
  }

  .searchPageContentIn {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    padding: 15px;
  }
}

/* 600 */
@media (max-width: 600px) {
  .downloadAppCard {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 29px 29px 0px;
  }

  .downloadAppCard .imageContainer {
    position: static;
    width: 100%;
    height: auto;
    margin-top: 30px;
  }
  .downloadAppLeft {
    width: 100%;
    align-items: center;
  }
}
