@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MuiAppBar-root {
  background-color: unset !important ;
  box-shadow: none !important;
}

.slick-dots {
  display: flex !important;
  justify-content: center;
  align-items: center;
  bottom: -20px;
  padding: 0;
  margin: 0;
}

.slick-dots li {
  width: auto;
  height: auto;
}

.slick-dots li button:before {
  font-size: 12px;
  color: #fff;
}

.slick-dots li.slick-active button:before {
  color: #000;
}
