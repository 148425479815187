.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.pac-container {
  background-color: #fff;
  border-top: 2px solid #f1f1f1;
  font-size: 12px;
  font-family: Mulish;
  border-radius: 2px 2px 20px 20px;
  max-height: 250px;
  overflow-y: auto;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.pac-item {
  padding: 3px 14px;
  cursor: pointer;
}

.pac-item:hover {
  background-color: #e0efeb;
  border-bottom: 1px solid #eaeaea;
}

.pac-item:active {
  background-color: #e1e1e1;
}

.pac-icon {
  display: none !important;
}
