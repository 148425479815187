.paddingResponsiveDestination {
  padding: 0px 80px;
}

.leftSidePadding {
  padding: 0 0 0 5rem;
}

.destinationDetailMain {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 110px;
}

.linksResponsive {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.viewAllImagesIn {
  display: flex;
  width: 50%;
  height: 100%;
  gap: 10px;
}

.viewAllImagesMain {
  position: relative;
  display: flex;
  width: 100%;
  height: 360px;
  border-radius: 20px;
  gap: 20px;
}

.ratingsMain {
  background-color: #f6f6f6;
  border: 1px solid #f1f1f1;
  display: flex;
  padding: 25px 20px 25px 50px;
  width: 100%;
  gap: 12%;
  height: 200px;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  /* justify-content: space-between; */
}

.reviewRatings {
  display: flex;
  gap: 20px;
  padding: 0px 80px;
}

.reviewsCard {
  padding: 30px 14px 30px 14px;
  gap: 0px;
  border-radius: 16px;
  border: 1px 0px 0px 0px;
  background: #ffffff;
  border: 1px solid #f1f1f1;
  box-shadow: 0px 2px 12px 0px #0000000f;
  /* height: 165px; */
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 10px;
}

.reviewsCardHeader {
  display: flex;
  gap: 10px;
}

.popup {
  position: fixed;
  top: 15%;
  right: 5%;
  transform: translateX(-50%);
  background-color: white;
  color: #20b08f;
  padding: 10px 20px;
  border-radius: 5px;
  border: 1px solid #20b08f;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  animation: fadeInOut 2s;
}

@keyframes fadeInOut {
  0%,
  100% {
    opacity: 0;
  }
  10%,
  90% {
    opacity: 1;
  }
}

.destinationCard {
  min-width: 150px;
  width: 230px;
  flex-shrink: 0;
  margin-right: 10px;
  background-color: #ddd;
  display: flex;
  border-radius: 21px;
  padding: 6px 6px 20px 6px;
  box-shadow: 0px 2px 18px 3px #00000005;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 15px;
  cursor: pointer;
  height: 320px;
}

.CardMain {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0px 0px 10px 10px;
  height: 100%;
  gap: 5px;
}

.allImagesPreviewMain {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 0px 150px;
}

.ratingCardsList {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 40px;
}

.mainPopularTags {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.viewAllImagesOne {
  width: 50%;
  height: 100%;
}

.destinationImageWrapper {
  height: 100%;
  width: 100%;
  border-radius: 20px 0px 0px 20px;
  object-fit: cover;
}

.viewAllImagesButton {
  position: absolute;
  padding: 9px 10px 9px 10px;
  gap: 6px;
  border-radius: 10px;
  border: 1px solid #333333;
  background-color: white;
  font-family: Mulish;
  font-weight: 700;
  text-align: center;
  font-size: 12px;
  cursor: pointer;
  bottom: 45px;
  right: 55px;
}

.allImagesPreviewResponsive {
  display: flex;
  flex-direction: column;
  gap: 50px;
  margin-top: 100px;
}

.allImagesPreviewMainMobile {
  display: none;
}

.destinationDetailMobileHeader {
  display: none;
}

.viewAllImagesBox {
  display: "flex";
  gap: "10px";
  width: "100%";
}

.hiddenLgFlex {
  display: none;
  /* width: 100%;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 20px; */
}

.flexLgHidden {
  display: flex;
  width: 100%;
  margin-bottom: 20px;
}

.viewAllImagesBox2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.headerDetailPageContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.headerTextContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.sideClicksContainer {
  margin-top: 2rem;
}

.avatarStyle {
  background-color: #20b08f;
  color: white;
  width: 50px;
  height: 50px;
  border-radius: 60px;
  font-size: 16px;
  font-weight: 500;
}

.sideClicksBox {
  display: flex;
  gap: 5px;
  align-items: center;
  cursor: pointer;
}

@media (max-width: 900px) {
  .leftSidePadding {
    padding: 0 0 0 20px;
  }
  .destinationDetailMain {
    margin-top: 0px;
  }

  .reviewRatings {
    width: 100%;
    flex-direction: column;
    padding: 0px 20px;
  }
  .paddingResponsiveDestination {
    padding: 0px 20px;
  }

  .viewAllImagesOne {
    width: 100%;
  }

  .viewAllImagesIn {
    display: none;
  }

  .allImagesPreviewResponsive {
    margin-top: 130px;
  }

  .allImagesPreviewMain {
    display: none;
  }

  .allImagesPreviewMainMobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 20px;
    width: 100%;
  }

  .viewAllImagesButton {
    right: 35px;
  }

  .destinationDetailMobileHeader {
    background-color: #ffffff;
    display: flex;
    gap: 10px;
    padding: 10px;
    align-items: center;
  }
}

@media screen and (min-width: 768px) {
  .headerDetailPageContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .headerTextContainer {
    gap: 0.5rem;
  }
  .sideClicksContainer {
    margin-top: 0;
  }
}

@media (min-width: 1024px) {
  .hiddenLgFlex {
    display: flex;
  }

  .flexLgHidden {
    display: none;
  }
}
