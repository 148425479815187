.popularAmongTravellersWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
}

.popularAmongTravellersWrapperIn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.gradientOverlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 60%; /* Adjust height for the gradient coverage */
  background: linear-gradient(to top, rgba(0, 0, 0, 0.8), transparent);
  border-radius: inherit;
}

.popularAmongTravellersCard {
  border-radius: 20px;
  width: 100%;
  cursor: pointer;
}

.cardsPlacementWeb {
  display: flex;
  gap: 20px;
  width: 100%;
}

.cardContent {
  position: absolute;
  bottom: 10px;
  left: 10px;
}

.cardList {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: "smooth";
}

.cardList::-webkit-scrollbar {
  display: none;
  /* Hide the scrollbar */
}

.cardsPlacementMobile {
  display: none;
}

.cardsWebPlacementScroll {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  margin-top: 70px;
}

@media (max-width: 768px) {
  .cardsPlacementWeb {
    display: none;
  }

  .cardsPlacementMobile {
    display: flex;
    gap: 20px;
  }
}

@media (max-width: 600px) {
  .popularAmongTravellersWrapper {
    gap: 0px;
  }
}
