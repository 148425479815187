.backgroundImg {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  min-height: 100vh;
  padding: 20px;
  /* margin-top: 100px; */
}

.searchPageMain {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
