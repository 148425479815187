/* atoms css starts */

.likesIconMain {
  height: 26px;
  padding: 4px 8px 4px 8px;
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  bottom: 10px;
  right: 10px;
}

.arrowIconMain {
  padding: 4px;
  gap: 10px;
  border-radius: 16px;
  background-color: #eaeaea;
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.arrowIconIn {
  padding: 5px;
  gap: 10px;
  border-radius: 12px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.passIconMain {
  background-color: #f6f6f64d;
  box-shadow: 0px 5px 8px 0px #0000000f;
  border-radius: 16px;
  padding: 4px;
  width: 38px;
  height: 38px;
  cursor: pointer;
}

.passIconIn {
  background-color: #ffffff;
  box-shadow: 0px 5px 8px 0px #0000000f;
  border-radius: 12px;
  padding: 5px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
}

.tabBackground {
  border-radius: 10px;
  display: flex;
  width: 100%;
  justify-content: center;
  min-height: 40px;
}

/* atoms css ends */

/* molecule css starts */

.card {
  min-width: 320px;
  max-width: 330px;
  flex-shrink: 0;
  margin-right: 10px;
  background-color: #ddd;
  display: flex;
  border-radius: 21px;
  padding: 6px;
  box-shadow: 0px 2px 18px 3px #00000005;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 15px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.card:hover {
  transform: scale(0.95);
}

.backgroundImg {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  min-height: 140px;
  border-radius: 12px;
  position: relative;
  object-fit: cover;
}

.cardDescription {
  display: flex;
  flex-direction: row;
  gap: 23px;
  margin-top: 4px;
}

.cardDescriptionItems {
  display: flex;
  flex-direction: row;
  gap: 4px;
}

/* template css starts */
.headerBox1 {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 5px;
  padding-left: 13px;
  padding: 0px 0px 8px 8px;
  border-bottom: 1px solid #eaeaea;
}

.headerMain {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 10px 15px 0px 15px;
  margin-top: -10px;
}

.headerPadding {
  padding: 0rem 60px;
  background-color: #ffffff;
  position: fixed;
  z-index: 1000;
  width: 100%;
  top: 0;
}

.mainPopularTags {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.scrollIcon {
  display: flex;
  gap: 20px;
  margin-right: 50px;
  height: 50px;
}

.cardListContainer {
  position: relative;
  width: 100%;
  overflow: hidden;
  margin: 0 auto;
  transition: padding-left 0.3s ease;
  padding-left: 20px;
}

.cardListContainer.removePadding {
  padding-left: 0;
}

.cardListTravellers {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  display: flex;
  gap: 10px;
  padding-left: 40px;
}

.cardListTravellers::-webkit-scrollbar {
  display: none;
}

.GetAppContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.leftSidePadding {
  padding: 6rem 0rem 0rem 5rem;
}

.scrollIcon {
  display: flex;
  gap: 20px;
  margin-right: 50px;
  height: 50px;
}

.cardListContainer {
  position: relative;
  width: 100%;
  overflow: hidden;
  margin: 0 auto;
  transition: padding-left 0.3s ease;
  padding-left: 20px;
  /* Default padding */
}

.cardListContainer.removePadding {
  padding-left: 0;
}

.cardListTravellers {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  display: flex;
  gap: 10px;
  padding-left: 40px;
}

.cardListTravellers::-webkit-scrollbar {
  display: none;
}

.contentGetApp {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 55%;
  padding: 60px;
}

.followersUIBox {
  display: flex;
  gap: 40px;
  /* padding: 10px 20px; */
  /* width: 100%; */
  justify-content: center;
  height: 70px;
}

.locationTagUi {
  padding: 8px 10px 8px 10px;
  background-color: #e0efeb;
  border-radius: 10px;
  display: flex;
  gap: 5px;
  max-width: 180px;
}

/* rectangle card css  */

.horizontalCard {
  width: 840px;
  height: 74px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 15px;
  border-radius: 24px;
  padding: 6px 0px 6px 20px;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
}

.horizontalCard:hover {
  border: 2px solid #79d0bc;
  background-color: #e0efeb1a; /* Change this to your desired background color */
}

.avatarContainer {
  background-color: #20b08f;
  color: white;
  width: 50px;
  height: 50px;
  border-radius: 16px;
  font-size: 12px;
  font-weight: bold;
}




@media (max-width: 900px) {
  .headerPadding {
    padding: 0rem;
    top: 30px;
  }

  .scrollIcon {
    display: none;
  }

  .cardListContainer {
    padding-left: 20px;
  }

  .leftSidePadding {
    padding: 0rem 0rem 0rem 0.5rem;
  }

  .GetAppContainer {
    display: flex;
    flex-direction: column;
  }

  .cardListContainer {
    padding-left: 20px;
    /* Default padding */
  }

  .cardListTravellers {
    padding-left: 0px;
  }

  .contentGetApp {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;
    padding: 50px;
    align-items: center;
  }

  .horizontalCard {
    width: 640px;
  }
}

@media (max-width: 400px) {
  .scrollIcon {
    display: none;
  }

  .horizontalCard {
    width: 400px;
  }
}
