.AuthMain {
  display: flex;
  gap: 20px;
}

.AuthResponsive {
  width: 420px;
  margin-top: 110px;
}

.ProceedingText {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  text-align: center;
}

.autoCompleteInput {
  background-color: transparent;
  padding: 4px;
  border: 1px solid #b1b1b4;
  border-radius: 16px;
  min-height: 42px;
  height: 6vh;
  max-height: 50px;
  margin: 0px;
  padding-left: 14px;
  font-size: 12px;
  box-shadow: 11px 10px 32px 0px #0000000a, -19px -7px 32px 0px #3333330a,
    1px 1px 2px 0px #00000021 inset;
}

.autoCompleteInput:focus {
  border-color: #20b08f;
}

.flexLgHidden {
  display: flex;
}

@media (max-width: 600px) {
  .AuthImg {
    display: none;
  }

  .AuthResponsive {
    width: 100%;
  }

  .flexLgHidden {
    display: none;
  }
}
